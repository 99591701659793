<template>
	<div :class="['sidebar', { 'sidebar-show': isShow }]">
		<div class="logo">
			<router-link :class="'logo-normal'" to="/">
				<div class="logo-img">
					<img :src="logoSrc" alt="logo" />
				</div>
			</router-link>
			<!-- <a class="simple-text logo-normal" href="/">
				<div class="logo-text">
					<span class="tertiary-color">NAME</span>
					<span class="primary-color">LOGOTYPE</span>
				</div>
			</a> -->

			<div class="navbar-minimize xs-hide md-show">
				<button
					class="minimize-button xs-hide md-show-inline"
					@click="emitEvent('minimizeSidebar')"
				>
					<i class="icomoon icon-menu text-center"></i>
				</button>
			</div>

			<el-button
				type="primary"
				icon="icomoon icon-cross"
				circle
				class="md-hide mobile-close-sidebar"
				@click="emitEvent('toggleSidebar')"
			/>
		</div>
		<div ref="sidebarScrollArea" class="sidebar-wrapper">
			<div class="sidebar-item user-menu">
				<!-- <el-menu-item index="/logout"> -->
				<div class="user-menu-container">
					<span class="collapse-text">
						<b>{{ authNameValue }}</b>
					</span>
					<i
						class="icomoon icon-sign-out"
						@click="changeRoute({ path: '/logout' })"
					/>
				</div>
				<!-- </el-menu-item> -->
			</div>

			<el-menu
				:unique-opened="true"
				:collapse-transition="false"
				:default-active="currentPath"
				class="el-menu-sidebar"
				:class="{ 'el-menu--collapse': isCollapse }"
				:menu-trigger="isCollapse ? 'hover' : 'click'"
				@select="path => changeRoute({ path: path })"
			>
				<!-- <li class="sidebar-item user-menu">
					<el-menu-item index="/logout">
						<div class="user-menu-container">
							<span>
								<b>{{ authUser.full_name }}</b>
							</span>
							<i class="suffix icomoon icon-sign-out" />
						</div>
					</el-menu-item>
				</li> -->

				<li
					v-for="menuCategory in navMenuItems"
					:key="'sidebar_nav_cat-' + menuCategory.name"
					class="sidebar-item"
				>
					<!-- <div class="" v-if="menuCategory.children && menuCategory.children.length"> -->
					<el-submenu
						v-if="menuCategory.children && menuCategory.children.length"
						:index="`category-${menuCategory.name}`"
					>
						<template slot="title">
							<i :class="['icomoon', menuCategory.icon]" />
							<span>{{ menuCategory.name }}</span>
						</template>

						<el-menu-item
							v-for="menuItem in menuCategory.children"
							:key="'sidebar_nav_link-' + menuItem.name"
							:index="menuItem.path"
						>
							<div class="">
								<i v-if="menuItem.icon" :class="['icomoon', menuItem.icon]" />
								<span>{{ menuItem.name }}</span>
							</div>
						</el-menu-item>
					</el-submenu>
					<!-- </div> -->

					<el-menu-item
						v-else
						:index="menuCategory.path"
						:class="{ 'is-active': isActiveItem(menuCategory.path) }"
					>
						<div>
							<i v-if="menuCategory.icon" :class="['icomoon', menuCategory.icon]" />
							<span>{{ menuCategory.name }}</span>
						</div>
					</el-menu-item>
				</li>
			</el-menu>

			<!-- <div class="sidebar-footer">
				<div class="footer-item motorLinkItem">
					<a :href="motorIQLink" target="_blank" v-if="motorIQLink" class="remote-auth-link standard">
						<img :src="logoSrc" alt="motorIQ" />
						<span class="collapse-text">MotorIQ</span>
					</a>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import { routesLinks } from '@/constants/routesLinks';
import { navigation } from '@/mixins';
import { logoSrc } from '@/constants/global';

export default {
	components: {
		// NavbarToggleButton,
		// MovingArrow
	},
	mixins: [navigation],
	props: {
		isCollapse: Boolean,
		isShow: Boolean,
		currentPath: String,

		logoMini: {
			type: String,
			default: '',
			description: 'Sidebar Logo mini'
		}
	},

	/*provide() {
		return {
			// autoClose: this.autoClose
		};
	},*/

	computed: {
		authUser() {
			return this.$store.state.auth.authUser;
		},

		logoSrc() {
			return logoSrc;
		},

		/*motorIQLink() {
			return this.$store.state.auth.motorIQLink;
		},*/

		navMenuItems() {
			return routesLinks.filter(link => {
				if (link.meta && link.meta.userTypes) {
					if (this.authUser) {
						return link.meta.userTypes.some(
							type => this.authUser.type === type
						);
					}
					return false;
				}
				return true;
			});
		},

		authNameValue() {
			const { authUser } = this;
			let value = '';
			if (authUser) {
				if (authUser.company && authUser.company.name) {
					value = authUser.company.name;
				} else {
					value = authUser.full_name;
				}
			}
			return value;
		}
	},

	methods: {
		/*handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},*/

		emitEvent(eventName, data) {
			this.$emit('event', eventName, data);
		},

		isActiveItem(itemPath) {
			const parts = this.$route.fullPath.split('/');
			if (parts.length) {
				const categoryPath = '/' + parts[1];
				if (categoryPath === itemPath) {
					return true;
				}
			}
			return false;
		}
	}
};
</script>
