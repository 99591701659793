import { USER_TYPES } from '@/constants/global';

const routesLinks = [
	/*{
		name: 'Login',
		icon: 'icon-search',
		path: '/login'
		// meta: {inNavbar:true}
	},*/
	/*{
		name: 'Dashboard',
		icon: 'icon-dashboard',
		path: '/dashboard'
		// meta: { userTypes: [USER_TYPES.INTERNAL] }
		// roles: ['manager', 'operator']
	},*/
	{
		name: 'Заказы',
		icon: 'icon-orders',
		path: '/orders',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Категории',
		icon: 'icon-category',
		path: '/categories',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Товары',
		icon: 'icon-product',
		path: '/products',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Атрибуты',
		icon: 'icon-attribute',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] },
		children: [
			{
				name: 'Группы атрибутов',
				path: '/attribute-groups'
			},
			{
				name: 'Атрибуты',
				path: '/attributes'
			}
		]
	},
	{
		name: 'Фильтры',
		icon: 'icon-filter',
		path: '/filters',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Дисконты',
		icon: 'icon-discount',
		path: '/discounts',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'SEO',
		icon: 'icon-SEO',
		path: '/seo',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	/* {
		name: 'Фильтры',
		icon: 'icon-filter',
		children: [
			{
				name: 'Фильтры',
				path: '/filters'
			},
			{
				name: 'Опции фильтров',
				path: '/filter-options'
			}
		]
	}, */
	{
		name: 'Заявки на звонок',
		icon: 'icon-phone secondary',
		path: '/callback-request',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Отзывы',
		icon: 'icon-review secondary',
		path: '/reviews',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Пользователи',
		icon: 'icon-users secondary',
		path: '/users',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Баннер',
		icon: 'icon-slider secondary',
		path: '/banner',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Настройки',
		icon: 'icon-settings secondary',
		path: '/settings',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Статусы заказов',
		icon: 'icon-order_status secondary',
		path: '/order-statuses',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Регионы доставки',
		icon: 'icon-location secondary',
		path: '/delivery-regions',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	},
	{
		name: 'Тэги',
		icon: 'icon-review secondary',
		path: '/tags',
		meta: { userTypes: [USER_TYPES.ADMIN, USER_TYPES.MANAGER] }
	}
];

export { routesLinks };
